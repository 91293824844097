// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import content from "../../studio-store-restaurant-theme/src/AppWebContent";
export const configJSON = require("./config");
//@ts-ignore
import copy from 'copy-to-clipboard';

export interface Props {
    navigation: any;
    id: string;
    history: any;
    myCartList: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    bannerImgList?: any;
    allCoupons?: Array<any>;
    showAllCoupons?: boolean;
    loader?: boolean
    couponCopied?: any;
    itemQuantity?: any;
    couponCode?: string;
    rewardsQuantity?: number;
    codeEmpty?: boolean;
    rewardIsEmpty: boolean,
    codeErrorContent?: string;
    isOpenCustomize?: boolean;
    customizedData?: any;
    toggleRewards?: boolean
    //for remove cartItem states
    isCartItemRemove?: boolean;
    cartItemId?: string;
    rewardContent?: string;
    isRewardEnable?: boolean;
    isCouponEnable?: boolean;
    isLoggedIn?: boolean;
    // Customizable Area Start
    // Customizable Area End
}

interface SS { }

export default class ShoppingCartController extends BlockComponent<
    Props,
    S,
    SS
> {
    getBannersApiCallId: string;
    getCartAPICallId: string;
    getAllCouponsAPICallId: string;
    updateCartApiCallId: string;
    applyCouponApiCallId: string;
    removeCouponApiCallId: string;
    applyRewardsApiCallId: string;
    removeRewardsApiCallId: string
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            bannerImgList: [],
            allCoupons: [],
            loader: false,
            showAllCoupons: false,
            couponCopied: "",
            rewardsQuantity: this.props?.myCartList?.order?.data?.attributes?.used_reward_balance && this.props?.myCartList?.order?.data?.attributes?.used_reward_balance,
            itemQuantity: 0,
            couponCode: this.props?.myCartList?.order?.data?.attributes?.coupon && this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code,
            codeErrorContent: '',
            codeEmpty: false,
            rewardIsEmpty: false,
            customizedData: '',
            toggleRewards: false,
            isRewardEnable: this.props?.myCartList?.order?.data?.attributes?.used_reward_balance && false,
            isCouponEnable: this.props?.myCartList?.order?.data?.attributes?.coupon_code_id && false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps) {
            this.setState({
                couponCode: nextProps?.myCartList?.order?.data?.attributes?.coupon && nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code ? nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code : "",
                isCouponEnable: nextProps?.myCartList?.order?.data?.attributes?.coupon_code_id && false,
                rewardsQuantity: nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance && nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance,
                isRewardEnable: nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance && false
            });
            const userData = localStorage.getItem("userData");
            const userCompletedInfo = localStorage.getItem("userCompleteInfo");
            const registartionNotCompleted = localStorage.getItem(
                "registrationDetails"
            );
            const isGuest = localStorage.getItem("guestUserID");
            if (userData && userCompletedInfo) {
                this.setState({
                    isLoggedIn: true,
                });
            }
            if (isGuest) {
                this.setState({
                    isLoggedIn: false,
                });
            }
            if (isGuest && userCompletedInfo) {
                this.setState({
                    isLoggedIn: true,
                });
            }
            if (userData && registartionNotCompleted) {
                this.setState({
                    isLoggedIn: false,
                });
            }
        }
        else {
            this.setState({
                couponCode: this.props?.myCartList?.order?.data?.attributes?.coupon && this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code ? nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code : "",
                isCouponEnable: this.props?.myCartList?.order?.data?.attributes?.coupon_code_id && false,
                rewardsQuantity: this.props?.myCartList?.order?.data?.attributes?.used_reward_balance && this.props?.myCartList?.order?.data?.attributes?.used_reward_balance,
                isRewardEnable: this.props?.myCartList?.order?.data?.attributes?.used_reward_balance && false
            });
        }
    }
    async componentDidMount() {
        const userData = localStorage.getItem("userData");
        if (userData) {
            this.setState({
                isLoggedIn: true,
            });
        }
        this.setState({
            rewardsQuantity: this.props?.myCartList?.order?.data?.attributes?.used_reward_balance && this.props?.myCartList?.order?.data?.attributes?.used_reward_balance,
            isRewardEnable: this.props?.myCartList?.order?.data?.attributes?.used_reward_balance && true,
            couponCode: this.props?.myCartList?.order?.data?.attributes?.coupon && this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code ? this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code : null,
            isCouponEnable: this.props?.myCartList?.order?.data?.attributes?.coupon_code_id && false,
        });
        this.getCoupons();
        this.getBannersImgList();
    };

    removeProductModalClose = () => {
        this.setState({
            isCartItemRemove: !this.state.isCartItemRemove
        });
    };
    findOrderItemOptions = (itemname: string, data: any) => {
        return data?.attributes?.order_item_options?.find((ele) => ele?.attributes?.name === itemname) ? true : false
    }
    handleCustomize = (data: any) => {
        // const [{ attributes: { catalogue: { catalogue_catalogue_attributes: [catalogue_catalogue_attribute_values] } } }] = data;
        // catalogue_catalogue_attribute_values.map((ele2: any, index: number) => {
        //     ele2.isSelected = this.findOptions(ele2?.attributes?.name, data)
        // })
        data?.attributes?.catalogue?.attributes?.catalogue_catalogue_attributes.map((ele: any, index: number) => {
            ele?.attributes?.catalogue_catalogue_attribute_values?.map((ele2: any, index: number) => {
                ele2.isSelected = this.findOrderItemOptions(ele2?.attributes?.name, data)
            })
        });
        console.log("&&&&&&&&&&&&&&&", data);
        this.setState({
            customizedData: data
        });
    };

    async receive(from: string, message: Message) {
        // runEngine.debugLog("MY Message Received", message);
        this.setState({ loader: false })
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallID = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJSON = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorMessage = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJSON && responseJSON.data) {

                if (apiRequestCallID !== null) {

                    //get all coupons
                    if (apiRequestCallID === this.getAllCouponsAPICallId) {
                        this.setState({
                            allCoupons: responseJSON.data,
                        });
                    }

                    //update cart
                    if (apiRequestCallID === this.updateCartApiCallId) {
                        this.setState({ itemQuantity: 0, isCartItemRemove: false })
                        window.notify([{ message: "Cart has been Updated.", type: "success" }]);
                        this.props?.getProducts();
                        this.props?.getCarts();
                    }

                    //coupon apply
                    if (apiRequestCallID === this.applyCouponApiCallId) {
                        this.setState({ itemQuantity: 0, loader: false })
                        window.notify([{ message: "Coupon successfully applied.", type: "success" }]);
                        this.props?.getCarts();
                    }

                    //remove coupon
                    if (apiRequestCallID === this.removeCouponApiCallId) {
                        this.setState({ loader: false, toggleRewards: false, couponCode: '' })
                        window.notify([{ message: "Coupon removed successfully.", type: "success" }]);
                        this.props?.getCarts();
                    }

                    //apply rewards
                    if (apiRequestCallID === this.applyRewardsApiCallId) {
                        this.setState({ itemQuantity: 0, loader: false, rewardContent: '', isRewardEnable: false })
                        window.notify([{ message: "Rewards successfully applied.", type: "success" }]);
                        this.props?.getCarts();
                    }

                    //remove rewards
                    if (apiRequestCallID === this.removeRewardsApiCallId) {
                        this.setState({ loader: false, toggleRewards: false, couponCode: '', rewardContent: '' })
                        window.notify([{ message: "Rewards Removed Successfully.", type: "success" }]);
                        this.props?.getCarts();
                    }

                    //get banners
                    if (apiRequestCallID === this.getBannersApiCallId) {
                        this.bannerImgListResponce(responseJSON.data?.banners?.data)
                    }
                }
            }
            if (responseJSON && responseJSON.errors && responseJSON.errors.length > 0) {
                if (apiRequestCallID !== null) {
                    if (apiRequestCallID === this.applyRewardsApiCallId) {
                        this.setState({
                            codeErrorContent: responseJSON.errors[0]?.message, isRewardEnable: true,
                            loader: false,
                        })
                    }
                    if (apiRequestCallID === this.applyCouponApiCallId) {
                        this.setState({
                            loader: false,
                        })
                        //@ts-ignore
                        window.notify([{ type: 'danger', message: responseJSON.errors[0].message }]);
                    }
                }
            }
            if (errorMessage) {
                this.setState({ loader: false })
                // window.notify([
                //     { type: "danger", message: errorMessage || content.someThingWent },
                // ]);
            }
        }
    }

    //get my banners
    getBannersImgList = (): boolean => {
        // Customizable Area Start
        this.setState({
            loader: true,
        });
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBannersApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getWebBannersAPIEndpoint
        );
        const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
        // Customizable Area End
    }

    /* START -------------------------------------------------------------- Banner Img List */
    bannerImgListResponce = (apiResData: any) => {
        const bannerData = apiResData?.filter((banner) => banner.attributes?.banner_position === 2 || banner.attributes?.banner_position === 3);
        const bannerDataImg = bannerData?.map((banner) => banner.attributes?.images);
        let bannerList = []
        for (let i = 0; i < bannerDataImg?.length; i++) {
            bannerList.push(bannerDataImg[i]?.data[0]?.attributes)
        }
        this.setState({ bannerImgList: bannerList })
    }
    /* END -------------------------------------------------------------- Banner Img List */

    //toggle View coupons Modal
    toggleCouponModal = () => {
        this.setState({
            showAllCoupons: !this.state.showAllCoupons,
        });
    };

    //get all coupons
    getCoupons = (): boolean => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCouponsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCouponsListAPIEndPoint
        );
        const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //copy coupon
    copyCoupon = (coupon: any, index: any) => {
        this.setState({ couponCopied: index, couponCode: coupon?.attributes?.code, codeEmpty: false, codeErrorContent: '', showAllCoupons: false })
        setTimeout(() => {
            this.applyCoupon(this.props?.myCartList?.order?.data)
        }, 1000)
    }

    //updateCart
    updateCartQuantity = (product: any, action: string) => {

        if (action === "Add") {
            this.setState({
                itemQuantity: (product?.attributes?.quantity + this.state.itemQuantity) + 1
            })
        } else if (action === "Subtract") {
            this.setState({
                itemQuantity: (product?.attributes?.quantity + this.state.itemQuantity) - 1
            })
        }

        setTimeout(() => {
            const header = {
                "Content-Type": configJSON.ApiContentType,
                token: localStorage.getItem("token"),
            };

            const httpBody = {
                "order_item_id": parseInt(product?.id),
                "quantity": this.state.itemQuantity
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.updateCartApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.orderBlockAPIEndpoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiPostMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;

        }, 200);

    }

    //apply Coupon
    applyCoupon = (cartData: any) => {
        this.setState({ codeEmpty: false, codeErrorContent: '', loader: true })
        if (this.state.couponCode.length > 0) {
            const header = {
                "Content-Type": configJSON.ApiContentType,
                token: localStorage.getItem("token"),
            };

            const httpBody = {
                "code": this.state.couponCode,
                "cart_value": parseFloat(cartData?.attributes?.sub_total)
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.applyCouponApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.orderBlockAPIEndpoint}/${cartData?.id}/apply_coupon`
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiPostMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;
        } else {
            this.setState({ codeEmpty: true, loader: false })
        }


    }

    //apply rewards
    applyReward = (cartData: any) => {
        let httpBody;
        if (this.state.rewardsQuantity === null) {
            this.setState({ codeErrorContent: `Rewards can't be empty` })
        } else if (this.state.rewardsQuantity <= this.props?.myCartList?.order?.data?.attributes?.reward_setting?.max_reward_usage_limit || this.state.rewardsQuantity === null) {
            this.setState({ loader: true, isRewardEnable: false, });

            const header = {
                "Content-Type": configJSON.ApiContentType,
                token: localStorage.getItem("token"),
            };

            if (this.props?.myCartList?.order?.data?.attributes?.reward_history_id !== null) {
                httpBody = {
                    "cart_value": parseFloat(cartData?.attributes?.sub_total),
                    "cart_id": parseInt(cartData?.id),
                    "used_reward": this.state.rewardsQuantity,
                    "reward_history_id": parseInt(cartData?.attributes?.reward_history_id)
                };

            } else {
                httpBody = {
                    "cart_value": parseFloat(cartData?.attributes?.sub_total),
                    "cart_id": parseInt(cartData?.id),
                    "used_reward": this.state.rewardsQuantity
                };

            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.applyRewardsApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.applyRewardsAPIEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiPostMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

            return true;

        }
        else if (this.state.couponCode?.length > 0 || this.state.couponCopied <= this.props?.myCartList?.order?.data?.attributes?.reward_setting?.max_reward_usage_limit) {
            this.setState({ codeErrorContent: 'Enter Rewards points under Limit bar' })
        }

    }

    //remove Coupon
    removeCoupon = (cartData: any) => {
        this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.ApiContentType,
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeCouponApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.orderBlockAPIEndpoint}/${cartData?.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiDeleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    //remove from cart
    removeProductFromCart = () => {
        this.setState({});
        const header = {
            "Content-Type": configJSON.ApiContentType,
            token: localStorage.getItem("token"),
        };
        const httpBody = {
            "order_item_id": this.state.cartItemId,
            "quantity": 0
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateCartApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.orderBlockAPIEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPostMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //remove applied rewards
    removeRewards = (cartData: any) => {
        this.setState({ loader: true })

        const header = {
            "Content-Type": configJSON.ApiContentType,
            token: localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeRewardsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.applyRewardsAPIEndPoint}/${cartData?.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiDeleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

}
