import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const config = {
  apiKey: "AIzaSyAdPsqRt53h5mBMwy-LCPVYiBLLsvqztvk",
  authDomain: "card-layout.firebaseapp.com",
  projectId: "card-layout",
  storageBucket: "card-layout.appspot.com",
  messagingSenderId: "875866302685",
  appId: "1:875866302685:web:3fd91ad293e5476e1cd334",
  measurementId: "G-P9XQ5WMR4F",
};

export let firebaseMessagingInstance = null;
// console.log("Aaaa",firebase.messaging())
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config);
  firebaseMessagingInstance = firebase.messaging();
  console.log("SSSSS", firebaseMessagingInstance);
} else {
  console.log("firebase not supported");
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    firebaseMessagingInstance._delegate
      .then(() => firebaseMessagingInstance.getToken())
      .then((firebaseToken) => {
        console.log("SSSSS", firebaseToken);
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    firebaseMessagingInstance.onMessage((payload) => {
      resolve(payload);
    });
  });
