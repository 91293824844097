Object.defineProperty(exports, "__esModule", {
  value: true,
});

/*** API Constants */
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

/*** Methods List */
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

/*** API's for Edit Profile */
exports.getUserProfileAPIEndPoint = "profile/profile";
exports.updateUserProfileAPIEndPoint = "profile/profile";

/*** API's for Mobile Banners */
exports.getMobileBannersListAPIEndPoint =
  "bx_block_banner/banners/mobile_banners_list";

/*** API's for Categories and Products */
exports.getCategoriesAndProductsListAPIEndPoint = "categories/categories";
exports.applyCategoryFilterAPIEndPoint =
  "filter_items/filtering/category_filter";

/*** API's for Wishlist */
exports.addToWishlistAPIEndPoint = "bx_block_wishlist/wishlists";
exports.removeFromWishlistAPIEndPoint =
  "bx_block_wishlist/wishlists/remove_catalogue";

/*** API's for Cart */
exports.updateCartAPIEndPoint = "order_management/orders";
exports.getCartCountAPIEndPoint = "order_management/orders/has_product";

/*** API's for Token */
exports.sendDeviceTokenAPiEndPoint = "account/devices";

/*** API's for catalogues */
exports.getAllProductsAPIEndPoint = "catalogue/catalogues";

/*** API's for Banner */
exports.getWebBannersAPIEndpoint = "bx_block_banner/banners/web_banners_list";

/*** API's for Cart */
exports.getCartBlockAPIEndPoint = "order_management/get_cart";

/*** API's for filter */
exports.getSearchBlockAPIEndpoint = "filter_items/filtering";
