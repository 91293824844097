//@ts-nocheck
import React, { Component, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

/** Routes List start */
//Footer
import Footer from "../../../studio-store-restaurant-components/src/AppWebFooter/Footer.web";

//Header
import Header from "../../../studio-store-restaurant-components/src/AppWebHeader/Header.web";

//LoginScreen
const LoginScreen = lazy(() =>
  import("../../../email-account-login-web/src/LoginScreen.web")
);

//phoneLogin
const PhoneLogin = lazy(() =>
  import("../../../email-account-login-web/src/PhoneLogin.web")
);

//Homescreen
import HomeDashboard from "../../../dashboard/src/Dashboard.web";

//checkoutScreen
const OrderSummary = lazy(() =>
  import("../../../OrderSummary/src/OrderSummary.web")
);

//orderSuccess
const OrderSuccess = lazy(() =>
  import("../../../OrderSummary/src/OrderSuccess.web")
);

//orderReject
const OrderReject = lazy(() =>
  import("../../../OrderSummary/src/OrderReject.web")
);

//ContactUs
const WebContactUs = lazy(() => import("../../../contactus/src/Contactus.web"));

//Help-Centers
const HelpCenterBlock = lazy(() =>
  import("../../../Settings2/src/HelpCenterBlock.web")
);

//AboutUs
const AboutUsBlockBlock = lazy(() =>
  import("../../../contactus/src/AboutUsBlock.web")
);

//OTP Screen
const OTPConfirm = lazy(() =>
  import("../../../otp-input-confirmation/src/OTPInput.web")
);

//Forgot Password
const ForgotPassword = lazy(() =>
  import("../../../forgot-password/src/ForgotPassword.web")
);

//Profile
const Profile = lazy(() =>
  import("../../../user-profile-basic/src/ProfileIndex.web")
);

//OrderDetailsView
const OrderDetailsView = lazy(() =>
  import("../../../OrderDetailViewWeb/src/OrderDetailsView.web")
);

//Review
const ReviewManagement = lazy(() =>
  import("../../../Settings2/src/Reviews.web")
);

//Review
const WishlistWeb = lazy(() =>
  import("../../../user-profile-basic/src/Wishlist.web")
);

/** Routes List End */

/** Private Routes start */
import PrivateRoute from "./PrivateRoute.web";
import ProtectedRoute from "./ProtectedRoute.web";
import Loader from "../../../../components/src/Loader";
// import WishlistWeb from "../../../user-profile-basic/src/Wishlist.web";
/** Private Roues End */

class WebRoutes extends Component {
  render() {
    const Appthem = JSON.parse(localStorage.getItem("appThemData"));
    const StripePubKey = Appthem?.PaymentKeys?.stripe?.stripe_pub_key
      ? Appthem?.PaymentKeys?.stripe?.stripe_pub_key
      : "";
    const stripePromise = loadStripe(StripePubKey);
    return (
      <div>
        <Elements stripe={stripePromise}>
        <Suspense fallback={<Loader />}>
          <Header />
          <Switch>
            <PrivateRoute
              path="/"
              exact
              render={(props) => <HomeDashboard {...props} />}
            />
            <Route
              path="/login"
              exact
              render={(props) => <LoginScreen {...props} />}
            />
            <Route
              path="/phoneLogin"
              exact
              render={(props) => <PhoneLogin {...props} />}
            />
            <Route
              path="/checkout"
              exact
              render={(props) => <OrderSummary {...props} />}
            />
            <Route
              path="/checkout/success"
              exact
              render={(props) => <OrderSuccess {...props} />}
            />
            <Route
              path="/checkout/reject"
              exact
              render={(props) => <OrderReject {...props} />}
            />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/contact-us" exact component={WebContactUs} />
            <Route path="/help-center" exact component={HelpCenterBlock} />
            <Route
              path="/help-center/:slug"
              exact
              component={HelpCenterBlock}
            />
            <Route path="/aboutus" exact component={AboutUsBlockBlock} />
            <Route path="/review" exact component={ReviewManagement} />
            {/* Private Routes */}
            <PrivateRoute
              path="/otpConfirm"
              exact
              render={(props) => <OTPConfirm {...props} />}
            />
            {/********************************** Module-3 *****************************************/}
            <ProtectedRoute
              path="/profile"
              exact
              render={(props) => <Profile {...props} />}
            />
            <ProtectedRoute
              path="/wish-list"
              exact
              render={(props) => <WishlistWeb {...props} />}
            />
            <ProtectedRoute
              path="/profile/myorder/:orderId/:itemId"
              exact
              render={(props) => <OrderDetailsView {...props} />}
            // component={OrderDetailsView}
            />
            <ProtectedRoute
              path="/profile/myorder/:orderId"
              exact
              render={(props) => <OrderDetailsView {...props} />}
            // component={OrderDetailsView}
            />
          </Switch>
          <Footer />
        </Suspense>
      </Elements>
      </div>
    );
  }
}

//@ts-ignore
export default withRouter(WebRoutes);
