import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { withRouter, Redirect } from "react-router-dom";
import { closeIcon } from "./assets";
// import commands from '../../commands';
// import langg from '../../language';
import "./css/search.web.css";
const ItemWithRouter: any = withRouter((props: any) => {
  
  const pushParams = (query:any) =>{
    let urlSearch = new URLSearchParams(window.location.search);
    urlSearch.delete("category_id");
    urlSearch.delete("favourite");
    urlSearch.set("q[name]", query);
    props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
  }
  return (
    <ListGroupItem
      {...props}
      onClick={() => {pushParams(props.query.attributes.name)
      }}
    />
  );
});

type MyProps = {
  testID: string;
  isMobile: boolean;
  isQuickResults: boolean;
  hideSearch: () => void;
  results: any;
};

type MyState = {};

class ExitSearch extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    // const lang = new langg('header');
    return (
      <>
        <div className="product-square" />
        <div className="yt-recent-search-wrap my-2">
          {this.props.isMobile && this.props.children}
          <div className="d-flex align-items-center justify-content-between mb-4">
            <p className="recent-search-tag-name m-0">
              {this.props.isQuickResults ? "Quick Results" : "Recent Searches"}
            </p>
            <img src={closeIcon} onClick={() => this.props.hideSearch()} />
          </div>
          <ListGroup className="recent-search-list-wrap" flush>
            {this.props.results.map((item: any, index: number) => {
              return (
                <ItemWithRouter
                  hideSearch={this.props.hideSearch}
                  className="px-0 w3-hover-opacity"
                  style={{ cursor: "default" }}
                  query={item}
                  isQuickResults={this.props.isQuickResults}
                >
                  <div onClick={() => this.props.hideSearch()}>
                    {this.props.isQuickResults
                      ? item.attributes.name
                      : item.name}
                  </div>
                </ItemWithRouter>
              );
            })}
          </ListGroup>
        </div>
      </>
    );
  }
}

//@ts-ignore
export default withRouter(ExitSearch);
