import React from "react";
import { withRouter } from "react-router-dom";
import FilterOptionListController, {
  Props,
  configJSON,
} from "./FilterOptionsListController.web";
import '../assets/css/filterOptions.css';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";


class FilterOptions extends FilterOptionListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let sliceCount = 6;
    if (window.innerWidth < 800) {
      sliceCount = 3
    }
    // Customizable Area Start
    return (
      // Customizable Area Start
      <>
        <div className="d-flex align-items-center">
          <div className="filter-option-inner-contnet">
            <div className="p-0 m-0 list-style-none profile-pg-tabs-name d-flex justify-content-center align-items-center">
              {/* <li
              >
                <div className="profile-item-wrap d-flex align-items-center myItem">
                  <span >{content.menu}</span>
                </div>
              </li> */}
              {this.props.myCategory?.slice(0, sliceCount).map((category: any, index: any) => (
                <div key={index}
                  onClick={() => this.routeToFilter(category?.id)}
                  // @ts-ignore
                  className={"fil-ops-activated"}
                >
                  <div className={this.state.activeCategoryId === category?.id ? "fil-ops active" : "fil-ops"}>
                    <span className="category-name">{category?.attributes?.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="position-relative more-fill-pills">
            <span style={{ fontSize: 18 }} onClick={() => this.setState({ more: !this.state.more })}>More &nbsp;{this.state.more ? <FaCaretUp color="#3fc1cb" /> : <FaCaretDown />}</span>
            {/* @ts-ignore */}
            <div className={this.state.more ? 'more-btn-inline' : 'more-btn-none'} >
              {/* @ts-ignore */}
              {this.props.myCategory?.slice(sliceCount, this.props.myCategory.length).map((category: any, index: any) => (

                <div key={index}
                  onClick={() => this.routeToFilter(category?.id)}
                  style={{ margin: '10px 35px' }}
                  // @ts-ignore
                  className={this.state.activeCategoryId === category?.id && "active"}

                >
                  <div style={{ padding: '12px 25px', fontSize: 18, borderBottom: '2px solid #DFDFDF', textAlign: 'center', fontWeight: 500, cursor: 'pointer' }}>
                    <span className="category-name">{category?.attributes?.name}
                      {/* &nbsp; ({category?.attributes?.product_count}) */}
                    </span>
                    <span className="border-bottom"></span>
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>

      </>
      // Customizable Area End
    );
    // Customizable Area End
  };
  // Customizable Area End
}

// @ts-ignore
export default withRouter(FilterOptions);
// Customizable Area Start
// Customizable Area End


