Object.defineProperty(exports, "__esModule", {
  value: true,
});

//content type list
exports.validationApiContentType = "application/json";

/*** Methods List */
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiGetMethod = "GET";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

//WEB API's
exports.getFAQAPIEndPoint = "bx_block_interactive_faqs/interactive_faqs";
exports.getHelpCenterAPIEndPoint = "bx_block_help_center/help_centers";
exports.getBrandSettingsAPIEndPoint = "bx_block_store_profile/brand_settings";
exports.getCommonSettingsAPIEndPoint = "bx_block_settings/common_settings";
