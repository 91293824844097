//@ts-ignore
import React from "react";
import FilteritemsController, {
  Props,
  configJSON,
} from "./FilterProductController.web";
import { withRouter } from 'react-router-dom';
import "../assets/css/filterProducts.css";
//@ts-ignore
import { Form, FormGroup, Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { AiOutlineDoubleRight } from "react-icons/ai";
import { MdKeyboardArrowUp } from 'react-icons/md';


import ListSkeletonLoader from '../../studio-store-restaurant-components/src/SkeletonLoader/ListSkeletonLoader'
// @ts-ignore
import {
  FoodTagVeg,
  FoodTagNonVeg,
  NotWishlisted,
  Wishlisted,
  modalCloseIcon,
} from "./assets";
import moment from "moment";
import { BsStarFill, BsStarHalf, BsX } from "react-icons/bs";
import { BiHeart } from "react-icons/bi";
// @ts-ignore
import _ from "lodash";
// @ts-ignore
import content from "../../studio-store-restaurant-theme/src/AppWebContent";
//@ts-ignore
import ShowMoreText from "react-show-more-text";


class FilterProducts extends FilteritemsController {
  // Customizable Area Start
  constructor(props: Props) {
    // Customizable Area Start
    super(props);

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const currencyType = JSON.parse(localStorage.getItem('country_info') || '{}')?.currency_type;
    const mySearch = new URLSearchParams(window.location.search)
    const secondExample = {
      size: 25,
      count: 5,
      color: "#DBDBDB",
      activeColor: "#ffa500",
      value: 1,
      a11y: true,
      isHalf: false,
      emptyIcon: <BsStarFill className="m-1" />,
      halfIcon: <BsStarHalf className="m-1" />,
      filledIcon: <BsStarFill className="m-1" />
    };
    return (
      // Customizable Area Start
      <div className="pr-8">

        {/* @ts-ignore */}
        {this.props?.myProductsList?.catalogues?.length > 0 ?
          <>
            {/* @ts-ignore */}
            {this.props?.myProductsList?.catalogues?.map((product: any, index: any) => (
              <div className="homepage-product-list-wrap radius-5 bg-white cart-pg-mb-30 product-main-list box-shadow" key={index}
              >
                <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col d-flex flex-column justify-content-between">
                    <div
                      className="cart-prodict-info w3-ripple"
                    >
                      <h2 className="cart-product-title text-capitalize">
                        {product?.attributes?.name}
                      </h2>
                      {product?.attributes?.description && <ShowMoreText
                        /* Default options */
                        lines={2}
                        more="Show more"
                        less="Show less"
                        className="food-subtitle"
                        anchorClass="show-more-text"
                        expanded={false}
                        width={350}
                        truncatedEndingComponent={"... "}
                        style={{ fontSize: "14px" }}
                      >
                        <p className="food-subtitle">
                          {product?.attributes?.description}
                        </p>
                      </ShowMoreText>
                      }

                      <div className="d-flex">
                        <div className="veg-nonVeg"><img src={product?.attributes?.non_veg ? FoodTagNonVeg : FoodTagVeg} className="img-fluid " />
                          {product?.attributes?.non_veg ? <span>&nbsp;&nbsp;Non-vegetarian</span> : <span>&nbsp;&nbsp;vegetarian</span>}
                        </div>
                      </div>

                      {/* <span className="customize-product-label">{product?.attributes?.availability === "in_stock" && product?.attributes?.catalogue_catalogue_attributes.length > 0 && "Customized*"}</span> */}
                    </div>
                    <div className="d-flex align-items-center pl-1 justify-content-between" >
                      <div>

                        {product?.attributes?.availability === "in_stock" &&
                          <>{product?.attributes?.on_sale ?
                            // @ts-ignore
                            <><span className="my-product-price">{currencyType} {product?.attributes?.sale_price}</span><span className="my-product-actual-price">{currencyType} {parseFloat(product?.attributes?.price).toFixed(1)}</span><span className="my-product-discount">save {parseFloat(product?.attributes?.discount).toFixed(0)}%</span></>
                            :
                            // @ts-ignore
                            <span className="my-product-price">{currencyType} {parseFloat(product?.attributes?.price).toFixed(1)}</span>
                          }</>
                        }
                      </div>
                      <div>

                        <>
                          {product?.attributes?.availability === "in_stock" ?
                            <>
                              {product?.attributes?.cart_quantity === null
                                ?
                                <button className="btn-add font-weight-bold" onClick={() => { product?.attributes?.catalogue_catalogue_attributes.length > 0 ? this.toggleAddToCartModal(product) : this.addToCart(product) }}>Add</button>
                                :
                                <>
                                  {product?.attributes?.catalogue_catalogue_attributes.length > 0
                                    ? <button className="btn-add font-weight-bold" onClick={() => { this.toggleAddToCartModal(product) }}>Add</button>
                                    :
                                    <>
                                      <div className="qty-input">
                                        <button className="qty-count qty-count--minus" data-action="minus" type="button"
                                          onClick={() => {
                                            if ((product?.attributes?.cart_quantity + this.state.itemQuantity) - 1 < 1) {
                                              //@ts-ignore
                                              window.notify([
                                                {
                                                  message: `You can not set less than 1 quantity`,
                                                  type: "warning",
                                                },
                                              ]);
                                            } else {
                                              this.updateCartQuantity(product, "Subtract");
                                            }
                                          }}
                                        >-</button>
                                        <input className="product-qty" type="number" name="product-qty" min="0" max="10" value={product?.attributes?.cart_quantity} />
                                        <button className="qty-count qty-count--add" data-action="add" type="button"
                                          onClick={() => {
                                            if ((product?.attributes?.cart_quantity + this.state.itemQuantity) + 1 > product.stock_qty) {
                                              //@ts-ignore
                                              window.notify([
                                                {
                                                  message: `You can not add more than ${product.stock_qty} quantity of this product`,
                                                  type: "warning",
                                                },
                                              ]);
                                            } else {
                                              this.updateCartQuantity(product, "Add");
                                            }
                                          }}
                                        >+</button>
                                      </div>
                                    </>
                                  }
                                </>
                              }
                            </> :
                            <span className="out-of-stock-span">{content.notAvailable}</span>
                          }
                        </>

                      </div>
                    </div>

                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col  mt-1 d-flex align-items-end">

                    <div
                      className="homepage-product-image w3-ripple position-relative"
                    >
                      <div className="hart-icon" onClick={() => product?.attributes?.wishlisted ? this.removeWishlist(product?.id) : this.addtoWishlist(product?.id)}>
                        <img src={product?.attributes?.wishlisted ? Wishlisted : NotWishlisted} className="img-fluid wishlistLogo" alt="product_image" />
                      </div>
                      {/* <img src={product?.attributes?.images?.data[0]?.attributes?.url} className="img-fluid" /> */}
                      <img src={product?.attributes?.images?.data[0]?.attributes?.url} className="img-fluid home-product-image" style={{ border: '1px solid #000000', objectFit: 'cover', borderRadius: 5 }} />
                    </div>
                  </div>

                </div>
              </div>
            ))}</> : new Array(3).fill(0).map(() =>
              <div className="homepage-product-list-wrap radius-5 bg-white cart-pg-mb-30 product-main-list box-shadow">
                <ListSkeletonLoader />
              </div>)
        }
        {/* @ts-ignore */}
        {this.props?.myProductsList?.meta?.pagination?.next_page !== null ?
          <div className="load-more-option">
            {/* @ts-ignore */}
            <span className="loadmore-option" onClick={() => this.props.updateProductsQuantity("Data")}>
              {/* @ts-ignore */}
              {!this.props?.myLoader && <>Load More <AiOutlineDoubleRight className="my-product-animator" /></>}
            </span>
          </div>
          :
          <div className="load-more-option">
            {/* @ts-ignore */}
            <span className="loadmore-option" onClick={() => window.scrollTo({
              top: 320,
              left: 0,
              behavior: "smooth",
            })}>
              {/* @ts-ignore */}
              Scroll To Top <MdKeyboardArrowUp className="my-product-animator" />
            </span>
          </div>
        }
        {/* @ts-ignore */}
        {/* Coupon Area Start */}
        <Modal className="cm-small-modal-6" isOpen={this.state && this.state.showAddToCart} toggle={() => this.toggleAddToCartModal("random")}>
          <ModalHeader className="add-addr-title-bar p-4" close={<img alt='close-btn' className="toggle-Modal" src={modalCloseIcon} onClick={() => this.toggleAddToCartModal("random")} />}>
            <span>{content.customize}</span>
          </ModalHeader>
          <ModalBody className=" yt-add-modal-body">
            <div className="ad-addr-body-wrap">
              <div className="modal-food-mainhead">
                {/* <img src={this.state.cartProduct?.attributes?.non_veg ? FoodTagNonVeg : FoodTagVeg} className="img-fluid " /> */}
                <div className="modal-food-heading">
                  {/* @ts-ignore */}
                  <h3>{this.state.cartProduct?.attributes?.name}</h3>
                </div>
                <div className="veg-nonVeg ml-3 p-1">
                  {/* @ts-ignore */}
                  <img src={this.state.cartProduct?.attributes?.non_veg ? FoodTagNonVeg : FoodTagVeg} className="img-fluid" />
                  {/* @ts-ignore */}
                  {this.state.cartProduct?.attributes?.non_veg ? <span>&nbsp;&nbsp;Non-vegetarian</span> : <span>&nbsp;&nbsp;Vegetarian</span>}
                </div>
              </div>

              <Formik
                //@ts-ignore
                innerRef={this.state.formRef}
                initialValues={{
                }}
                validationSchema={() => {
                  //@ts-ignore
                  return Yup.object().shape({
                    radioButton: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
                  })
                }}
                onSubmit={(values) => {
                  this.calculateCartTotal(values)
                }}

              >
                {(props) => {
                  const { errors, touched, values, handleBlur, handleChange, handleReset, handleSubmit } = props;

                  return (
                    <Form onSubmit={handleSubmit} noValidate style={{ paddingTop: "20px" }}>
                      {/* @ts-ignore */}
                      {this.state.cartProduct?.attributes?.catalogue_catalogue_attributes.length > 0 &&
                        <>
                          {/* @ts-ignore */}
                          {this.state.cartProduct?.attributes?.catalogue_catalogue_attributes.map((attributes: any, index: any) => (
                            <>
                              <div className="modal-attributes-section" key={index}>
                                <span>{attributes?.attributes?.name}</span>
                                {attributes?.attributes?.catalogue_catalogue_attribute_values?.length > 0 && attributes?.attributes?.catalogue_catalogue_attribute_values?.map((microAttribute: any, index: any) => (
                                  <FormGroup >
                                    <span className='modalTitleInputLable'>{microAttribute?.attributes?.name}</span>
                                    {/* @ts-ignore */}
                                    <Field value={`${microAttribute?.attributes?.price},${microAttribute?.id}`} type={attributes?.attributes?.control_type === "radio_button" ? "radio" : "checkbox"} name={attributes?.attributes?.name} id={microAttribute?.id} className="form-check-input" onClick={() => this.state.formRef?.current?.handleSubmit()} />
                                  </FormGroup>
                                ))}
                              </div>
                              {/* @ts-ignore */}
                              {this.state.cartProduct?.attributes?.catalogue_catalogue_attributes.length - 1 !== index && <div className="modal-section-divider" />}

                            </>
                          ))}

                        </>}
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="item-total">
              <span>
                {content.itemTotal}
              </span>
              <div className="item-total-flex">
                {/* @ts-ignore */}
                <div>{currencyType} {this.state.cartTotal === 0 ? <>{this.state.cartProduct?.attributes?.on_sale ? this.state.cartProduct?.attributes?.sale_price : this.state.cartProduct?.attributes?.price}</> : this.state.cartTotal}</div>
                <span>+{content.taxes}</span>
              </div>
            </div>
            {/* @ts-ignore */}
            <Button className="modal-addtocart-btn"
              // disabled={this.state.cartTotal === 0 ? true : false}
              onClick={() => this.addToCart("Modal")}>
              {content.addItem}
            </Button>
          </ModalFooter>
        </Modal>
        {/*  Coupon Area End */}

        {/* Review Area Start */}
        {/*  Review Area End */}
        {/* <div className="radius-10 bg-white cart-pg-mb-30" style={{padding:'25px 40px'}} >
            <div className="cart-pg-product-list-row justify-content-between">
              <div className="bg-white">
                <div className="d-flex justify-content-between">
                <div className="mr-35">
                  <div>
                  <h5 className="smt-0 mb-3 font-weight-bold">Dish title</h5>
                    <p className="pp-od-no-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. uidem aliquam vitae esse iure sint temporibus, dllo!
                    </p>
                  </div>
                    <div className="d-flex veg-product">
                      <img src="" />
                      <span>Vegetarian</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-weight-bolds">12.34</span>
                      <button className="btn-add font-weight-bold">Add</button>
                    </div>
                  </div>
                  <div className="img-pro">
                  <img 
                  src="https://cdn-prod.medicalnewstoday.com/content/images/articles/322/322303/selection-of-chinese-food-that-may-cause-chinese-restaurant-syndrome.jpg"
                  />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>*/}
      </div >
      // Customizable Area End
    );
    // Customizable Area End
  };
  // Customizable Area End
}
// @ts-ignore
export default withRouter(FilterProducts);
// Customizable Area Start
// Customizable Area End

