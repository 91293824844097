// App.js - WEB
import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import HomeScreen from "../../../../components/src/HomeScreen";
/** WebRoutes file **/
// @ts-ignore
import WebRoutes from "./webRoutes";
import ScrollToTopButton from "react-scroll-to-top";
import { ToastContainer } from "react-toastify";
// @ts-ignore
import { onMessageListener, requestFirebaseNotificationPermission } from './firebaseInit.js';

//css
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "./App.css";

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: "HomeScreen"
  };
  const homeScreen = new HomeScreen(defaultProps);
}

// const firebaseAPI = firebase.initializeApp({
//   apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
//   authDomain: "rnmasterapp-c11e9.firebaseapp.com",
//   databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
//   projectId: "rnmasterapp-c11e9",
//   storageBucket: "rnmasterapp-c11e9.appspot.com",
//   messagingSenderId: "649592030497",
//   appId: "1:649592030497:web:7728bee3f2baef208daa60",
//   measurementId: "G-FYBCF3Z2W3",
// });

class App extends Component {
  async componentDidMount() {
    // const messaging = firebaseAPI.messaging();
    // console.log("messaging",messaging.getToken())
    // messaging.getToken().then(() => {
    //   return messaging.getToken()
    // }).then(token => {
    //   console.log("@@@ FCM =========== 1", token)
    // }).catch(error => {
    //   console.log("@@@ FCM =========== 2", error)
    // })

    requestFirebaseNotificationPermission()
      .then((firebaseToken: any) => {
        console.log("@@@ FCM =========== 1", firebaseToken)
        localStorage.setItem('fcmToken', firebaseToken.toString());
      })
      .catch((err: any) => {
        return err;
      });
  }

  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // console.log("defaultAnalytics", defaultAnalytics)
    // defaultAnalytics.logEvent("APP_Loaded");

    return (
      <>
        <WebRoutes />
        <ScrollToTopButton smooth />
        <ToastContainer />
      </>
    );
  }
}

export default App;
