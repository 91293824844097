Object.defineProperty(exports, "__esModule", {
  value: true
});

/*** API Constants */
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

/*** Methods List */
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

/*** API's For Products */
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";

/*** API's For Catalogue */
exports.getAllProductsAPIEndPoint="catalogue/catalogues";
exports.postWishlistAPIEndpoint ="bx_block_wishlist/wishlists";
exports.delWishlistAPIEndpoint="bx_block_wishlist/wishlists/remove_catalogue/";
exports.orderBlockAPIEndpoint = "order_management/orders";