Object.defineProperty(exports, "__esModule", {
  value: true
});

/*** API Constants */
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

/*** Methods List */
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

/*** API's for Cart */
exports.getCartDetailsAPIEndPoint = "order_management/get_cart";
exports.showCartDetailsAPIEndPoint = "order_management/orders";
exports.updateCartAPIEndPoint = "order_management/orders";
exports.getCartCountAPIEndPoint = "order_management/orders/has_product";
exports.getCouponsListAPIEndPoint = "coupon_cg/coupon_code_generator";
exports.applyCouponAPIEndPoint = "order_management/orders";
exports.removeCouponAPIEndPoint = "order_management/orders";
exports.applyRewardsAPIEndPoint = "bx_block_loyaltysystem/rewards";
exports.removeRewardsAPIEndPoint = "bx_block_loyaltysystem/rewards";
exports.getWebBannersAPIEndpoint = "bx_block_banner/banners/web_banners_list"

/*** API's for Block Order */
exports.orderBlockAPIEndpoint = "order_management/orders";