// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import content from "../../studio-store-restaurant-theme/src/AppWebContent";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  myCategory?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeCategoryId: String;
  filterParams?: any;
  more: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class FilterOptionListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCategoryAPICallId: string;
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activeCategoryId: "",
      filterParams: new URLSearchParams(window.location.search),
      more: false,
      // Customizable Area End
    };
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start

    this.getActiveCat();
    // Customizable Area End
  }

  componentWillReceiveProps(prevState: any) {
    // Customizable Area Start
    this.getActiveCat();

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("MY Message Received", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }
    // Customizable Area End
  }

  //mark category active & sort product
  getActiveCat = () => {
    // Customizable Area Start
    let urlSearch = new URLSearchParams(window.location.search);
    if (urlSearch.get("category_id") !== null) {
      this.setState({
        activeCategoryId: urlSearch.get("category_id"),
        more: false,
      });
    } else {
      this.setState({ activeCategoryId: "" });
    }
    // Customizable Area End
  };

  //route to filtered category
  routeToFilter = (value: any) => {
    // Customizable Area Start
    let urlSearch = new URLSearchParams(window.location.search);
    urlSearch.delete("q[name]");
    urlSearch.delete("favourite");
    urlSearch.set("category_id", value);
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
    // Customizable Area End
  };

  // get category list

  // Customizable Area End
}
