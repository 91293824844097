//@ts-ignore
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Collapse,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    Button
} from 'reactstrap';

//@ts-ignore
import capitalize from "lodash/capitalize";
import SearchData from './searchData.web';

//images
import { cartIcon, logo, searchIcon, menuIcon, userIcon, locationIcon, cartItemIcon, HeartImg } from './assets';
//controller
import HeaderController, { Props } from './HeaderController.web';
//content file
//@ts-ignore
import content from '../../../studio-store-restaurant-theme/src/AppWebContent';
//css 
import './css/headerStyle.css';

// @ts-ignore
let MobileMenu: any = withRouter((props: any) => {
    const isMobileMenu = props.isOpen;
    const toogleMenu = props.toogle;
    const isLoggedIn = props.isLoggedIn;
    const isMobileMenuItems = props.isMobileMenuItem;
    return (

        <div className={isMobileMenu ? 'yt-only-mobile-vw tr' : 'yt-only-mobile-vw '} style={{ cursor: 'pointer' }}>
            <div className="yt-mobile-side-nav-wrap">
                <div className="yt-mobile-side-inner-content">
                    <div className="yt-inner-cnt-logo">
                        <div className="logobox-mb">
                            <img
                                src={(props.themData && props.themData.commonLogoSrc) ? props.themData.commonLogoSrc : logo}
                                className="logo-img"
                                alt={(props.themData && props.themData.commonLogoSrc) ? props.themData.ExtraFields?.heading : ""}
                                onClick={() => {
                                    props?.history?.push('/')
                                    toogleMenu()
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div className="yt-inner-cnt">
                        {!isLoggedIn
                            ?
                            (
                                <div className="yt-mb-user-profile d-flex" style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        props?.history?.push('/login');
                                        toogleMenu();
                                    }}>
                                    <div className="yt-mb-nav-login-btn">{content.loginNSignup}</div>
                                </div>
                            )
                            :
                            (
                                <div className="yt-mb-user-profile d-flex" style={{ cursor: 'pointer' }} onClick={() => { toogleMenu() }}>
                                    <div className="yt-header-user-img" />
                                    <div className="yt-head-user-det" onClick={() => { props?.history?.push({ pathname: '/profile', state: { activeTab: "1" } }), toogleMenu }}>
                                        <div className="yt-head-name">
                                            {/* @ts-ignore */}
                                            {JSON.parse(localStorage.getItem('userData'))?.name}
                                        </div>
                                        <div className="yt-head-email">
                                            {/* @ts-ignore */}
                                            {JSON.parse(localStorage.getItem('userData'))?.email}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="yt-mobile-nav-content">
                        {isLoggedIn
                            ?
                            (
                                <div className="yt-mobile-nav-content" style={{ cursor: 'default' }}>
                                    <Navbar color='light' light expand='md'>
                                        <Collapse isOpen={isMobileMenu} navbar>
                                            <Nav className='mr-auto' navbar>
                                                <NavItem>
                                                    {isMobileMenuItems.map((ele: any, index: number) => (
                                                        <NavLink key={index}
                                                            onClick={() => {
                                                                props?.history?.push({
                                                                    pathname: "/profile",
                                                                    state: {
                                                                        activeTab: ele.tabTitle == 'Logout' ? "logout" : ele.tabTitle
                                                                    }
                                                                });
                                                                toogleMenu();
                                                            }}>
                                                            {ele.tabTitle}
                                                        </NavLink>
                                                    ))}
                                                </NavItem>
                                            </Nav>
                                        </Collapse>
                                    </Navbar>
                                </div>
                            )
                            :
                            (
                                <div className="yt-mobile-nav-content" style={{ cursor: 'default' }}>
                                    <Navbar color="light" light expand="md">
                                        <Collapse isOpen={isMobileMenu} navbar>
                                            <Nav className="mr-auto" navbar>
                                                <NavItem>
                                                    <NavLink onClick={() => {
                                                        props?.history.push('/help-center');
                                                        toogleMenu();
                                                    }}>
                                                        {content.helpCenter}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink onClick={() => {
                                                        props?.history.push('/contact-us');
                                                        toogleMenu();
                                                    }}>
                                                        {content.ContactUs}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Collapse>
                                    </Navbar>
                                </div>
                            )}
                    </div>
                </div>
                <div className={'h-100'} onClick={toogleMenu} />
            </div>
        </div>
    )
});

class Header extends HeaderController {

    closeLoactionDrop = () => {
        this.setState({
            isOpenLocation: false
        })
    }

    render() {
        //@ts-ignore
        const appThemeData = JSON.parse(localStorage.getItem('appThemData'));
        return (
            <header className={this.state.headerScrolled ? "yt-main-header myHeader2" : "yt-main-header myHeader1"} >
                <>
                    <div className="logo-container dashboard-carousel-section" >
                        <Row className="align-items-center">
                            <Col className="yt-head-col">
                                <div className="d-flex align-items-center"  >
                                    <img src={menuIcon} alt='' className='menuicon d-md-none w3-ripple' onClick={() => {
                                        this.toogleMobileMenu()
                                    }} />
                                    <Link to={"/"}>
                                        <div className="logo-img">
                                            <img

                                                src={appThemeData?.commonLogoSrc ? appThemeData?.commonLogoSrc : logo}
                                                className="logo-img"
                                                alt={appThemeData?.ExtraFields?.heading ? capitalize(appThemeData?.ExtraFields?.heading) : ""}
                                            />

                                        </div>
                                    </Link>
                                    {/* <div className="supportbox">
                                        <div className="support">
                                            <RiHeadphoneLine className="supportimage" />
                                            <div className="supportnumber">
                                                <span className="title"> {content.callUs} </span>
                                                <span className="number">{appThemeData?.commonTextsContent?.callUs ? appThemeData?.commonTextsContent?.callUs : content.dummyContactNumber}</span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </Col>

                            <Col className="yt-head-col d-flex justify-content-end align-items-center">
                                <div className="yt-head-hide-srch-col mr-24">
                                    <div className="searchcontainer header-search-field-wrap">
                                        <input
                                            type="text"
                                            placeholder={
                                                'Search'
                                            }
                                            className="searchinput"
                                            value={this.state.searchQuery}
                                            onClick={() => this.setState({ searchDropDown: true })}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchQuery: e.target.value
                                                })
                                                setTimeout(() => {
                                                    this.state.searchQuery != "" && this.getLiveSearch()
                                                }, 300)
                                            }}
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter' && this.state.searchQuery != "") {
                                                    this.setState({ searchDropDown: false })
                                                    setTimeout(() => {
                                                        this.pushParams()
                                                    }, 200);
                                                }
                                            }}
                                            onFocus={() => {
                                                this.setState({ searchDropDown: false })
                                            }}
                                        />
                                        <img
                                            src={searchIcon}
                                            alt="search"
                                            className="searchicon w3-hover-opacity"
                                            // className="searchicon w3-ripple w3-hover-opacity search-icon"
                                            onClick={() => this.state.searchQuery !== "" &&
                                                setTimeout(() => {
                                                    this.setState({ searchDropDown: false })
                                                    this.pushParams()
                                                }, 200)}
                                        />
                                        {/* <label className="search-label">Store Address</label> */}
                                        {this.state.searchDropDown && this.state.searchQuery != '' && (
                                            <SearchData
                                                hideSearch={() => {
                                                    this.setState({
                                                        searchQuery: "",
                                                        searchDropDown: false
                                                    })
                                                }}
                                                // @ts-ignore
                                                results={this.state.quickResults?.catalogues}
                                                isQuickResults={true}
                                            />
                                        )}

                                    </div>
                                </div>
                                <div className="cartcontainer py-2" style={{
                                    flexWrap: localStorage.getItem("userData") ? 'unset' : 'wrap'
                                }}>
                                    <div onClick={() => {
                                        //@ts-ignore
                                        this.props?.history.push({ pathname: '/wish-list', state: { isWhishList: true } })
                                    }}>
                                        <span className="d-inline-block yt-cart-icn-mainwp mr-24">
                                            {this.state.isFavoriteHasItems?.length > 0 && <span className="cart-item-dot">{this.state.isFavoriteHasItems?.length}</span>}
                                            <img
                                                src={HeartImg}
                                                alt="cart"
                                                className="carticon w3-ripple"
                                            />
                                        </span>
                                    </div>
                                    <div onClick={() => {
                                        //@ts-ignore
                                        this.props?.history.push('/checkout')
                                    }}>
                                        <span className={this.state.isLoggedIn ? "mr-24 yt-cart-icn-mainwp" : "d-inline-block yt-cart-icn-mainwp"}>
                                            {this.state.isCartHasItems?.length > 0 && <span className="cart-item-dot">{this.state.isCartHasItems?.length}</span>}
                                            {this.state.isCartHasItems
                                                ? <img
                                                    src={cartIcon}
                                                    alt="cart"
                                                    className="carticon w3-ripple"
                                                /> : <img
                                                    src={cartIcon}
                                                    alt="cart"
                                                    className="carticon w3-ripple"
                                                />}
                                        </span>
                                    </div>
                                    {/* @ts-ignore */}
                                    {!this.state.isLoggedIn ? (
                                        <Button
                                            className="loginbutton header-log-btn w3-ripple ml-2"
                                            onClick={() => {
                                                this.props?.history?.push('/login');
                                            }}
                                        >
                                            <span className='mobileContent'>{content.login}</span>
                                            <span className='desktopContent'>{content.loginNSignup}</span>
                                        </Button>
                                    ) : (
                                        <div
                                            className="userbox w3-ripple d-flex align-items-center"
                                            onClick={() => {
                                                //@ts-ignore
                                                this.props?.history.push('/profile')
                                            }}
                                            title={'Go to Profile'}
                                        >
                                            <span>
                                                <img
                                                    //@ts-ignore
                                                    src={JSON.parse(localStorage.getItem('userData'))?.image ? JSON.parse(localStorage.getItem('userData'))?.image : userIcon}
                                                    alt="user"
                                                    className="usericon"
                                                />
                                            </span>
                                            {/* @ts-ignore */}
                                            <span className="uname">{JSON.parse(localStorage.getItem('userData'))?.name}</span>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className=" yt-head-mb-search-panel" >
                            {/* Search Data */}
                        </div>
                    </div>
                    <MobileMenu
                        isOpen={this.state.isMobileMenu}
                        toogle={() => this.toogleMobileMenu()}
                        isLoggedIn={this.state.isLoggedIn}
                        isMobileMenuItem={this.state.mobileMenuItems}
                    />
                </>

            </header >

        )
    }
};

//@ts-ignore
export default withRouter(Header);