// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { firebaseMessagingInstance } from '../../studio_store_restaurant_router/src/web/firebaseInit.js';


export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNonVeg?: boolean;
  isVeg?: boolean;
  loader?: boolean;
  itemQuantity?: any;
  productList?: Array<any>;
  categoryList?: Array<any>;
  cartList?: Array<any>;
  bannerImgList?: any;
  numberOfProduct?: number;
  isFavorites?: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllProductAPICallId: string;
  getCategoriesAPICallId: string;
  getCartAPICallId: string;
  getBannersApiCallId: string;
  sendDeviceTokenApiCallId: any;
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      loader: false,
      isVeg: false,
      productList: [],
      categoryList: [],
      bannerImgList: [],
      cartList: [],
      itemQuantity: 0,
      numberOfProduct: 10,
      isNonVeg: false,
      isFavorites: false,
      // Customizable Area End
    };
    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    this.asyncToggle();
    this.getBannersImgList();
    this.getProductList();
    // this.getCategoryList();
    this.getCartList();
    this.onFcmRegister();
    this.props.history.push('/?category_id=1')
    // Customizable Area End

  }

  componentWillReceiveProps(nextProps: any) {
    // Customizable Area Start
    this.asyncToggle();
    this.getProductList();
    this.getCategoryList();
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("MY Message Received", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJSON && responseJSON.data) {

        if (apiRequestCallID !== null) {
          //get products
          if (apiRequestCallID === this.getAllProductAPICallId) {
            this.setState({
              loader: false,
              productList: responseJSON.data,
            });
            if (this.state.isFavorites) {
              let result = responseJSON.data?.catalogues?.filter((ele: any, index: number) => {
                if (ele?.attributes?.wishlisted) {
                  return ele;
                }
              });
              const finalArray = {
                "catalogues": [...result],
                "meta": responseJSON.data.meta
              }
              this.setState({
                productList: finalArray
              })
            }
          }
          //get banners
          if (apiRequestCallID === this.getBannersApiCallId) {
            this.bannerImgListResponce(responseJSON.data?.banners?.data)
          }
          //get categories
          if (apiRequestCallID === this.getCategoriesAPICallId) {
            this.setState({
              loader: false,
              categoryList: responseJSON.data
            })
          }

          //get cart list
          if (apiRequestCallID === this.getCartAPICallId) {
            this.setState({
              loader: false,
              cartList: responseJSON.data,
            });
            localStorage.setItem("cartId", responseJSON.data?.order?.data?.id)
            if (responseJSON.data?.order) {
              const { data } = responseJSON.data?.order;
              localStorage.setItem("isCartHaveItems", data?.attributes?.order_items?.length > 0 && true,)
            }

          }

          // if (apiRequestCallID === this.sendDeviceTokenApiCallId) {
          //   this.sendDeviceTokenSuccessCallBack(responseJson);
          // }
        }
      }
      if (responseJSON && responseJSON.errors && responseJSON.errors.length > 0) {
        if (apiRequestCallID !== null) {
          if (apiRequestCallID === this.getCartAPICallId) {
            if (responseJSON.errors[0].token == "Token has Expired") {
              localStorage.clear();
              this.props?.history?.push({ pathname: '/login', state: { activeTab: "2" } })
            }
          }
        }
      }
    }
    // Customizable Area End
  }

  /* START -------------------------------------------------------------- Banner Img List */
  bannerImgListResponce = (apiResData: any) => {
    const bannerDataImg = apiResData.filter((banner) => banner?.attributes?.banner_position === 1)?.map((banner) => banner?.attributes?.images);
    let bannerList = []
    for (let i = 0; i < bannerDataImg?.length; i++) {
      for (let j = 0; j < bannerDataImg[i]?.data?.length; j++) {
        if (bannerDataImg[i]?.data[j]?.attributes) {
          bannerList.push(bannerDataImg[i]?.data[j]?.attributes)
        }
      }
    }
    this.setState({ bannerImgList: bannerList })


  }
  /* END -------------------------------------------------------------- Banner Img List */

  //toggle Veg
  toggleVeg = (value: any) => {
    // Customizable Area Start
    this.setState({ isVeg: value, isNonVeg: false });
    let urlSearch = new URLSearchParams(window.location.search);
    let isParams = new URLSearchParams(window.location.search).get("non_veg");
    if (value) {
      if (isParams == "true") {
        urlSearch.delete("non_veg");
        urlSearch.append("non_veg", false);
        this.setState({ isVeg: true, isNonVeg: false });
      }
      else {
        urlSearch.append("non_veg", false);
        this.setState({ isVeg: true, isNonVeg: false });
      }
    } else {
      urlSearch.delete("non_veg");
      this.setState({ isVeg: false });
    }
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
    // Customizable Area End
  };

  //toggle Non-Veg
  toggleNonVeg = (value: any) => {
    // Customizable Area Start
    this.setState({ isNonVeg: value, isVeg: false });
    let urlSearch = new URLSearchParams(window.location.search);
    let isParams = new URLSearchParams(window.location.search).get("non_veg");
    if (value) {
      if (isParams == "false") {
        urlSearch.delete("non_veg");
        urlSearch.append("non_veg", true);
        this.setState({ isNonVeg: true, isVeg: false });
      }
      else {
        urlSearch.append("non_veg", true);
        this.setState({ isNonVeg: true, isVeg: false });
      }
    } else {
      urlSearch.delete("non_veg");
      this.setState({ isNonVeg: false });
    }
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
    // Customizable Area End
  }

  //toggle My Favorites
  toggleMyFavorites = (value: any) => {

    // Customizable Area Start
    this.setState({
      isFavorites: true,
    });
    let urlSearch = new URLSearchParams(window.location.search);
    let isParams = new URLSearchParams(window.location.search).get("favourite");
    urlSearch.delete("category_id");
    urlSearch.delete("q[name]");
    if (!value) {
      if (isParams == "false") {
        urlSearch.append("favourite", true);
        this.setState({
          isFavorites: true
        });
      }
      else {
        urlSearch.append("favourite", true);
        this.setState({
          isFavorites: true
        });
      }
    } else {
      urlSearch.delete("favourite");
      this.setState({
        isFavorites: false,
      });
    }
    // this.props.history.push(`/`);
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
    console.log(decodeURIComponent(urlSearch.toString()), value, "MYDECODEURL");

    // Customizable Area End
  }

  //update quantity
  productsQuantity = (value: any) => {
    // Customizable Area Start
    this.setState({ numberOfProduct: this.state.numberOfProduct + 10 })
    setTimeout(() => {
      this.getProductList();
    }, 300);
    // Customizable Area End
  }

  //async toggle
  asyncToggle = () => {
    // Customizable Area Start
    if (new URLSearchParams(window.location.search).get("non_veg") !== null || new URLSearchParams(window.location.search).get("favourite") !== null) {
      if (new URLSearchParams(window.location.search).get("non_veg") == "true" && new URLSearchParams(window.location.search).get("favourite") == true) {
        this.setState({ isNonVeg: true, isFavorites: true });
      }
      if (new URLSearchParams(window.location.search).get("non_veg") == "false" && new URLSearchParams(window.location.search).get("favourite") == true) {
        this.setState({ isVeg: true, isFavorites: true });
      }
      if (new URLSearchParams(window.location.search).get("favourite") == true) {
        this.setState({ isFavorites: true });
      }
    }
    else {
      this.setState({ isVeg: false, isNonVeg: false, isFavorites: false });
    }
    // Customizable Area End
  };

  // get product list
  getProductList = () => {
    // Customizable Area Start
    this.setState({
      loader: true,
      itemQuantity: 0,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let urlSearch = new URLSearchParams(window.location.search);
    this.getAllProductAPICallId = requestMessage.messageId;

    if (window.location.search === "" || urlSearch.get("q[name]") !== null) {
      if (window.location.search === "") {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getAllProductsAPIEndPoint}?per_page=${this.state.numberOfProduct}`
        );
      } else if (urlSearch.get("non_veg") !== null) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getSearchBlockAPIEndpoint}?q[name]=${urlSearch.get('q[name]')}&q[non_veg]=${urlSearch.get('non_veg')}&per_page=${this.state.numberOfProduct}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getSearchBlockAPIEndpoint}?q[name]=${urlSearch.get('q[name]')}&per_page=${this.state.numberOfProduct}`
        );
      }
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAllProductsAPIEndPoint}${window.location.search}&per_page=${this.state.numberOfProduct}`
      );
    }

    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  //get Categories
  getCategoryList = () => {
    // Customizable Area Start
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesAPICallId = requestMessage.messageId;
    //@ts-ignore
    if (window.location.search === "" || new URLSearchParams(window.location.search).get("q[name]") !== null || new URLSearchParams(window.location.search).get("favourite") !== null) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCategoriesAndProductsListAPIEndPoint
      );
    } else {
      if (
        new URLSearchParams(window.location.search).get("non_veg") !== null
      ) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.applyCategoryFilterAPIEndPoint
          }?q[non_veg]=${new URLSearchParams(window.location.search).get("non_veg")}`
        );
      } else if (
        new URLSearchParams(window.location.search).get("category_id") !== null
      ) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCategoriesAndProductsListAPIEndPoint
        );
      }
    }

    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }

  //get my banners
  getBannersImgList = (): boolean => {
    // Customizable Area Start
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBannersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWebBannersAPIEndpoint
    );
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
    // Customizable Area End
  }

  // get cart list
  getCartList = (): boolean => {
    // Customizable Area Start
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCartBlockAPIEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
    // Customizable Area End
  };
  // Customizable Area End


  /* START -------------------------------------------------------------- Firebase access device */
  onFcmRegister = async () => {
    let fcmToken = await localStorage.getItem("fcmToken");
    let data = {
      data: {
        attributes: {
          token: fcmToken,
          platform: 'web',
        }
      }
    }
    this.sendDeviceTokenApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.sendDeviceTokenAPiEndPoint,
      body: data,
    });
    this.setupNotification()
  };

  setupNotification = () => {
    console.log("@@@ FCM ===========', remoteMessage", firebaseMessagingInstance)
    firebaseMessagingInstance.onMessage(async (remoteMessage: any) => {
      console.log('@@@ FCM ===========', remoteMessage);
      if (remoteMessage && remoteMessage.notification) {
        if (remoteMessage.notification.title.toLowerCase() === 'order delivery update' || remoteMessage.notification.title.toLowerCase() === 'order confirmed') {
          console.log("Event Fire")
        }
      }
    });
  };

  sendDeviceTokenSuccessCallBack = (res: any) => {
    console.log('@@@ Send Device Token Success CallBack ===================', res);
  };
  /* END -------------------------------------------------------------- Firebase access device */


  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = await localStorage.getItem("token") || "";
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
}
