// @ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

//@ts-ignore
const configJSON = require("./config.js");

//content file
//@ts-ignore
import content from "../../../studio-store-restaurant-theme/src/AppWebContent";

export interface Props {
  history?: any;
}
export interface S {
  searchQuery: string;
  headerScrolled: boolean;
  isLoggedIn?: boolean;
  isOpenLocation?: boolean;
  searchDropDown: boolean;
  quickResults?: Array<any>;
  searchStore: string;
  isFavoriteHasItems: [];

  //cart state
  isCartHasItems: [];

  //mobile menu state
  isMobileMenu: boolean;
  mobileMenuItems: Array<any>;
}
export interface SS { }
let isErrorMessageShowOnce = true
export default class HeaderController extends BlockComponent<Props, S, SS> {
  getLiveSearchApiCallId: string;
  getCartApiCallId: string;
  getFavoriteListCallId: string;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      searchQuery: "",
      headerScrolled: false,
      isMobileMenu: false,
      searchDropDown: false,
      quickResults: [],
      isCartHasItems: [],
      isFavoriteHasItems: [],
      mobileMenuItems: [
        { tabTitle: "My Favorites", tabId: "2" },
        { tabTitle: "Reviews", tabId: "3" },
        { tabTitle: "My Orders", tabId: "4" },
        // { tabTitle: "My Bookings", tabId: "5" },
        { tabTitle: "Saved Addresses", tabId: "6" },
        { tabTitle: "Help Center", tabId: "7" },
        { tabTitle: "Contact Us", tabId: "8" },
        { tabTitle: "Notifications", tabId: "9" },
        { tabTitle: "Logout", tabId: "10" },
      ],
      searchStore: ""
    };
  }

  async componentDidMount() {
    const userData = localStorage.getItem("userData");
    window.addEventListener("scroll", this.listenScrollEvent.bind(this));
    if (userData) {
      this.setState({
        isLoggedIn: true,
      });
    }
    this.getCartItems();
    this.getFavoriteListItems();
  }

  async componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent.bind(this));
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps) {
      const userData = localStorage.getItem("userData");
      const userCompletedInfo = localStorage.getItem("userCompleteInfo");
      const registartionNotCompleted = localStorage.getItem(
        "registrationDetails"
      );
      const isGuest = localStorage.getItem("guestUserID");
      if (userData && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (isGuest) {
        this.setState({
          isLoggedIn: false,
        });
      }
      if (isGuest && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (userData && registartionNotCompleted) {
        this.setState({
          isLoggedIn: false,
        });
      }
      this.getCartItems();
    }
  }
  async listenScrollEvent(event: any) {
    if (window.scrollY < 73) {
      this.setState({
        headerScrolled: false,
      });
    } else if (window.scrollY > 250) {
      this.setState({
        headerScrolled: true,
      });
    }
  }

  //Mobile Menu
  toogleMobileMenu = () => {
    this.setState({
      isMobileMenu: !this.state.isMobileMenu,
    });
  };

  //check API's Reponses function
  async receive(form: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJSON && responseJSON.data) {
        if (apiRequestCallID !== null) {
          //get live searches
          if (apiRequestCallID === this.getLiveSearchApiCallId) {
            this.setState({
              quickResults: responseJSON.data,
            });
          }
          //get Cart Items
          if (apiRequestCallID === this.getCartApiCallId) {
            if (responseJSON.data?.order) {
              const { data } = responseJSON.data?.order;
              this.setState({
                isCartHasItems: data?.attributes?.order_items || [],
              })
            }
          }

          if (apiRequestCallID === this.getFavoriteListCallId) {
            if (responseJSON.data?.wishlist) {
              const { data } = responseJSON.data?.wishlist;
              this.setState({
                isFavoriteHasItems: data?.attributes?.wishlist_items ?? [],
              })
            }
          }

        }
      }

      if (isErrorMessageShowOnce) {
        if (errorMessage) {
          window.notify([
            { type: "danger", message: errorMessage || content.someThingWent },
          ]);
        }
        isErrorMessageShowOnce = false
      }

    }
  }

  //push recent search param
  pushParams = () => {
    let urlSearch = new URLSearchParams(window.location.search);
    urlSearch.delete("category_id");
    urlSearch.delete("favourite");
    urlSearch.set("q[name]", this.state.searchQuery);
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
  };

  /// get search live
  getLiveSearch = (): boolean => {
    const headers = {
      "Content-Type": configJSON.filterContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLiveSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetLiveSearch}?q[name]=${this.state.searchQuery}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get cart info
  getCartItems = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCartApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCartAPIEndPoint);
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getFavoriteListItems = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFavoriteListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getFavoriteListAPIEndPoint);
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
}
