import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import ShoppingCartController, {
  Props,
  configJSON,
} from "./ShoppingCartController.web";
//@ts-ignore
import "../assets/css/shoppingCart.css";
import '../assets/css/removeCartProduct.css';
import EmptyCart from "./EmptyCartContent.web";
import { FaPlus, FaMinus } from "react-icons/fa";
import { BsX } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import {
  Button,
  Table,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { FiChevronsRight } from "react-icons/fi";

import { FaCopy } from "react-icons/fa";
import {
  FoodTagVeg,
  FoodTagNonVeg,
  Banner1,
  Banner2,
  modalCloseIcon,
  rewardIcon,
  Banner3,
  Banner4
} from "./assets";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Loader from "../../../components/src/Loader";
//@ts-ignore
import content from '../../studio-store-restaurant-theme/src/AppWebContent';
import { AiFillStar } from "react-icons/ai";
import AdvBannerSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/AdvBannerSkeltonLoader";


class ShoppingCart extends ShoppingCartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const currencyType = JSON.parse(localStorage.getItem('country_info') || '{}')?.currency_type;
    return (
      <>
        {/* {this.state.loader && <Loader loading={this.state.loader} />} */}
        {/* @ts-ignore */}
        {this.props?.myCartList?.order && this.props?.myCartList?.order?.data?.attributes?.order_items && this.props?.myCartList?.order?.data?.attributes?.order_items.length > 0 ?
          <div>
            <div className="bg-white yt-cart-price-lister">
              <div className="my-order-cart-title">{content.yourCart}</div>
              <span className="homepage-cart-divider mb-4" />
              {this.props?.myCartList?.order?.data?.attributes?.order_items.map((inCart: any, index: any) => (
                <div className="row" key={index}>
                  <div className="col-md-6 col-sm-6 col-6">
                    <div className="cart-prodict-info">
                      <h2 className="cart-product-title mt-2 text-capitalize">
                        {inCart?.attributes?.catalogue?.attributes?.name}
                      </h2>

                      {inCart?.attributes?.order_item_options && inCart?.attributes?.order_item_options?.map((item: any) => (
                        <p className="food-subtitle" style={{ color: "#757575" }}>
                          <span>{item?.attributes?.name}, </span>
                        </p>
                      ))}
                    </div>
                    <div className="d-flex">
                      <div className="veg-nonVeg">
                        <img src={inCart?.attributes?.catalogue?.attributes?.non_veg ? FoodTagNonVeg : FoodTagVeg} className="img-fluid" />
                        {inCart?.attributes?.catalogue?.attributes?.non_veg ? <span>&nbsp;&nbsp;Non-vegetarian</span> : <span>&nbsp;&nbsp;Vegetarian</span>}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-4">
                    <span className="cart-product-amount-qty d-flex justify-content-end align-items-center">
                      <FaRegTrashAlt className="remove-item-svg" onClick={() => {
                        this.setState({
                          isCartItemRemove: true,
                          cartItemId: inCart?.id,
                        })
                      }} />
                      <div className="qty-input">
                        <button className="qty-count qty-count--minus" data-action="minus" type="button"
                          onClick={() => {
                            if ((inCart?.attributes?.cart_quantity + this.state.itemQuantity) - 1 < 1) {
                              //@ts-ignore
                              window.notify([
                                {
                                  message: `You can not set less than 1 quantity`,
                                  type: "warning",
                                },
                              ]);
                            } else {
                              this.updateCartQuantity(inCart, "Subtract");
                            }
                          }}
                        >-</button>
                        <input className="product-qty" type="number" name="product-qty" min="0" max="10" value={inCart?.attributes?.quantity} />
                        <button className="qty-count qty-count--add" data-action="add" type="button"
                          onClick={() => {
                            if ((inCart?.attributes?.cart_quantity + this.state.itemQuantity) + 1 > inCart.stock_qty) {
                              //@ts-ignore
                              window.notify([
                                {
                                  message: `You can not add more than ${inCart.stock_qty} quantity of this product`,
                                  type: "warning",
                                },
                              ]);
                            } else {
                              this.updateCartQuantity(inCart, "Add");
                            }
                          }}
                        >+</button>
                      </div>
                    </span>
                  </div>
                  <div className="col-md-2 col-sm-2 col-2 text-end">
                    <div className="product-cart-amount">
                      {/* @ts-ignore */}
                      <span style={{ marginTop: 4 }} className="cart-product-amount-price">{currencyType} {inCart?.attributes?.total_price}</span>
                    </div>
                  </div>
                </div>
              ))}

              <span className="homepage-cart-divider mt-3" />
              <Table className="mb-0 cart-prodict-total-amount " borderless>
                <tbody>
                  <>
                    {/* @ts-ignore */}
                    {parseFloat(this.props?.myCartList?.order?.data?.attributes?.sub_total) > 0 &&
                      <tr>
                        <td>
                          <span className="cart-product-amount-ttl" style={{ fontWeight: "bold" }}>
                            {content.subTotal}
                          </span>
                        </td>
                        <td>
                          <span className="cart-product-amount-price" style={{ fontWeight: "bold" }}>
                            {/* @ts-ignore */}
                            {currencyType} {this.props?.myCartList?.order?.data?.attributes?.sub_total}
                          </span>
                        </td>
                      </tr>
                    }
                    {/* @ts-ignore */}
                    {this.props?.myCartList?.order?.data?.attributes?.total_tax > 0 &&
                      <tr>
                        <td>
                          <span className="cart-product-amount-ttl" style={{ fontSize: "14px" }}>{content.taxes}</span>
                        </td>
                        <td>
                          <span className="cart-product-amount-price" style={{ fontSize: "14px" }}>
                            {/* @ts-ignore */}
                            + {currencyType} {parseFloat(this.props?.myCartList?.order?.data?.attributes?.total_tax).toFixed(1)}
                          </span>
                        </td>
                      </tr>
                    }
                    {/* @ts-ignore */}
                    {parseFloat(this.props?.myCartList?.order?.data?.attributes?.shipping_charge) > 0 &&
                      <tr>
                        <td>
                          <span className="cart-product-amount-ttl" style={{ fontSize: "14px" }}>
                            {content.deliveryCharges}
                          </span>
                        </td>
                        <td>
                          <span className="cart-product-amount-price" style={{ fontSize: "14px" }}>
                            {/* @ts-ignore */}
                            + {currencyType} {this.props?.myCartList?.order?.data?.attributes?.shipping_charge}
                          </span>
                        </td>
                      </tr>
                    }
                    {/* @ts-ignore */}
                    {parseFloat(this.props?.myCartList?.order?.data?.attributes?.shipping_discount) > 0 &&
                      <tr>
                        <td>
                          <span className="cart-product-amount-ttl" style={{ fontSize: "14px" }}>
                            {content.deliveryDiscount}
                          </span>
                        </td>
                        <td>
                          <span className="cart-product-amount-price" style={{ fontSize: "14px" }}>
                            {/* @ts-ignore */}
                            - {currencyType} {this.props?.myCartList?.order?.data?.attributes?.shipping_discount}
                          </span>
                        </td>
                      </tr>
                    }
                    {/* @ts-ignore */}
                  </>
                  {/* } */}
                </tbody>
              </Table>
              <div>
                <Table
                  className="mt-2 mb-0 cart-prodict-total-amount "
                  borderless
                >
                  <tbody>
                    {/* @ts-ignore */}
                    {this.props?.myCartList?.order?.data?.attributes?.total_discount > 0 &&
                      <tr>
                        <td>
                          <span className="cart-product-amount-ttl" style={{ fontSize: "14px" }}>
                            {content.discount}
                          </span>
                        </td>
                        <td>
                          <span className="cart-product-amount-price" style={{ fontSize: "14px" }}>
                            {/* @ts-ignore */}
                            - {currencyType} {parseFloat(this.props?.myCartList?.order?.data?.attributes?.total_discount).toFixed(1)}
                          </span>
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>

              <span className="homepage-cart-divider" />
              <div className="cart-coupon mt-3">
                {/* @ts-ignore */}
                {this.state.toggleRewards && this.props?.myCartList?.order?.data?.attributes?.coupon_code_id !== null &&
                  <>
                    <div className="my-cart-applied-code">
                      <span className="applied-coupon-code" onClick={() => this.toggleCouponModal()}>
                        {content.appliedCoupon}
                      </span>
                      {/* @ts-ignore */}
                      {/* {this.props?.myCartList?.order?.data?.attributes?.reward_setting?.apply_coupon_and_reward && */}
                      <span className="applied-coupon-code" onClick={() => this.setState({ toggleRewards: true })}>
                        {/* @ts-ignore */}
                        {this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes?.code}
                        {/* @ts-ignore */}
                        <BsX onClick={() => this.removeCoupon(this.props?.myCartList?.order?.data)} className="remove-item-svg" />
                      </span>
                      {/* } */}
                    </div>
                    <span className="homepage-cart-divider" />
                  </>
                }
                {/* @ts-ignore */}
                {this.state.toggleRewards && this.props?.myCartList?.order?.data?.attributes?.reward_history_id !== null &&
                  <>
                    <div className="my-cart-applied-code">
                      <span className="applied-coupon-code" onClick={() => this.toggleCouponModal()}>
                        {content.appliedReward}
                      </span>
                      {/* @ts-ignore */}
                      {/* {this.props?.myCartList?.order?.data?.attributes?.reward_setting?.apply_coupon_and_reward && */}
                      <span className="applied-coupon-code" onClick={() => this.setState({ toggleRewards: true })}>
                        {/* @ts-ignore */}
                        {this.props?.myCartList?.order?.data?.attributes?.used_reward_balance}
                        {/* @ts-ignore */}
                        <BsX onClick={() => { this.removeRewards(this.props?.myCartList?.order?.data) }} />
                      </span>
                      {/* } */}
                    </div>
                    <span className="homepage-cart-divider" />
                  </>
                }
                <div className="my-cart-engagements">
                  <>
                    {!this.state.toggleRewards &&
                      <>
                        <span className="cart-coupon-code-message availableCoupons" onClick={() => this.toggleCouponModal()}>
                          {content.viewAvailableCoupons}
                        </span>

                        {/* @ts-ignore */}
                        {/* {this.props?.myCartList?.order?.data?.attributes?.reward_setting?.apply_coupon_and_reward && this.props?.myCartList?.order?.data?.attributes?.current_reward_balance !== null && */}
                        <span className="cart-coupon-code-message availableCoupons" onClick={() => this.setState({ toggleRewards: true })}>
                          {content.applyRewards}
                        </span>
                        {/* } */}
                      </>
                    }
                    {/* @ts-ignore */}
                    {this.state.toggleRewards && this.props?.myCartList?.order?.data?.attributes?.coupon_code_id === null &&
                      <>
                        <span className="cart-coupon-code-message availableCoupons" onClick={() => this.toggleCouponModal()}>
                          {content.viewAvailableCoupons}
                        </span>

                        <span className="cart-coupon-code-message availableCoupons" onClick={() => this.setState({ toggleRewards: false })}>
                          {content.applyCoupons}
                        </span>
                      </>
                    }
                  </>
                </div>
                {this.state.toggleRewards ? <Form className="yt-cart-disct-wrap pb-4">
                  {/* @ts-ignore */}
                  {/* {this.props?.myCartList?.order?.data?.attributes?.reward_setting?.apply_coupon_and_reward == true &&  */}
                  <span className="reward-balance-span">
                    You have{" "}
                    <label>
                      <img src={rewardIcon} alt='' />{' '}
                      {/* @ts-ignore */}
                      {this.props?.myCartList?.order?.data?.attributes?.current_reward_balance} Reward points Left.
                    </label>{" "}
                    {/* @ts-ignore */}
                    10 For every {currencyType} {parseFloat((this.props?.myCartList?.order?.data?.attributes?.reward_setting?.exchange_rate) * 10).toFixed(1)}
                  </span>
                  {/* } */}
                  <FormGroup
                    className="m-0 yt-form-cpn-err error"
                  /* yt-form-cpn-err success */
                  >
                    <input
                      type="number"
                      className="form-control"
                      id="cart-total-products-amount"
                      placeholder="Apply Rewards"
                      value={this.state.rewardsQuantity || ''}
                      onChange={(e) => {
                        //@ts-ignore
                        let limitValue = this.props?.myCartList?.order?.data?.attributes?.reward_setting?.max_reward_usage_limit;
                        if (limitValue < e.target.value) {
                          //@ts-ignore
                          window.notify([{ type: 'danger', message: "Rewards Point Not Available" }]);
                          this.setState({ rewardsQuantity: parseInt(e.target.value), codeErrorContent: '' });
                        } else {
                          this.setState({ rewardsQuantity: parseInt(e.target.value), codeErrorContent: '', });
                        }
                      }}
                      // @ts-ignore
                      // max={this.props?.myCartList?.order?.data?.attributes?.reward_setting?.max_reward_usage_limit}
                      // @ts-ignore
                      disabled={!this.state.isRewardEnable && this.props?.myCartList?.order?.data?.attributes?.reward_history_id !== null}
                      maxLength={15}
                    />
                    <div className="pb-3 d-flex align-items-center cart-coupon-bottom-wrapper justify-content-between">
                      <>
                        {/* @ts-ignore */}
                        {this.props?.myCartList?.order?.data?.attributes?.reward_history_id && this.props?.myCartList?.order?.data?.attributes?.applied_reward_discount !== null && !this.state.rewardContent &&
                          <span
                            className="cart-coupon-code-message success-message"
                            style={{ color: "#6cb83a", display: "block" }}
                          >{/* @ts-ignore */}
                            You saved {currencyType}{this.props?.myCartList?.order?.data?.attributes?.applied_reward_discount} </span>
                        }
                        <span
                          className="cart-coupon-code-message rewards-max-quantity"
                        >
                          {/* @ts-ignore */}
                          Max Limit {this.props?.myCartList?.order?.data?.attributes?.reward_setting?.max_reward_usage_limit} Points at a time
                        </span>
                        {/* @ts-ignore */}
                        {this.props?.myCartList?.order?.data?.attributes?.reward_history_id && <Button color=" cart-coupon-change-btn p-0" onClick={() => this.removeRewards(this.props?.myCartList?.order?.data)}>
                          Remove Rewards
                        </Button>
                        }
                      </>
                    </div>
                    {this.state.codeErrorContent &&
                      <span className="cart-coupon-code-message error-message">
                        {this.state.codeErrorContent}
                      </span>
                    }
                    {/* @ts-ignore */}
                    {parseInt(this.props?.myCartList?.order?.data?.attributes?.applied_reward_discount) > 0 && !this.state.isRewardEnable
                      ? <Button color=" cart-coupon-btn" onClick={() => {
                        this.setState({
                          isRewardEnable: true
                        })
                      }}>Change</Button>
                      :
                      //  @ts-ignore
                      <Button color=" cart-coupon-btn" onClick={() => this.applyReward(this.props?.myCartList?.order?.data)}>Apply</Button>}
                    {/* {this.props?.myCartList?.order?.data?.attributes?.coupon_code_id !== null ? <Button color=" cart-coupon-btn" onClick={() => this.removeCoupon(this.props?.myCartList?.order?.data)}>Change</Button> : <Button color=" cart-coupon-btn" onClick={() => this.applyReward(this.props?.myCartList?.order?.data)}>Apply</Button>} */}
                  </FormGroup>
                </Form>
                  :
                  <Form className="yt-cart-disct-wrap pb-4">
                    <FormGroup
                      className="m-0 yt-form-cpn-err error"
                    /* yt-form-cpn-err success */
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="cart-total-products-amount"
                        placeholder="Apply Coupon"
                        value={this.state.couponCode}
                        onChange={(e) => this.setState({ couponCode: e.target.value, codeEmpty: false, codeErrorContent: '' })}
                        // @ts-ignore
                        disabled={!this.state.isCouponEnable && this.props?.myCartList?.order?.data?.attributes?.coupon_code_id !== null}
                        maxLength={15}
                      />
                      <div className="pb-3 d-flex align-items-center cart-coupon-bottom-wrapper justify-content-between">
                        {/* @ts-ignore */}
                        {this.props?.myCartList?.order?.data?.attributes?.coupon_code_id !== null && !this.state.codeErrorContent &&
                          <>
                            <span
                              className="cart-coupon-code-message success-message"
                              style={{ color: "#6cb83a", display: "block" }}
                            >
                              {/* @ts-ignore */}
                              You saved {currencyType}{this.props?.myCartList?.order?.data?.attributes?.applied_discount}
                            </span>
                            {/* @ts-ignore */}
                            <Button color=" cart-coupon-change-btn p-0" onClick={() => this.removeCoupon(this.props?.myCartList?.order?.data)}>
                              Remove Coupon
                            </Button>
                          </>
                        }

                        {this.state.codeEmpty &&
                          <span className="cart-coupon-code-message error-message">
                            {this.state.codeEmpty &&
                              "Coupon code can't be empty"
                            }
                          </span>
                        }
                      </div>
                      {/* @ts-ignore */}
                      {!this.state.isCouponEnable && this.props?.myCartList?.order?.data?.attributes?.coupon_code_id !== null ?
                        <Button color=" cart-coupon-btn" onClick={() =>
                          this.setState({
                            isCouponEnable: true
                          })
                        }>Change</Button>
                        :
                        //  @ts-ignore
                        <Button color=" cart-coupon-btn" onClick={() => this.applyCoupon(this.props?.myCartList?.order?.data)}>Apply</Button>}
                    </FormGroup>
                  </Form>

                }
              </div>
              <Table
                className="mb-0 cart-prodict-sub-total-amount "
                borderless
              >
                <tbody>
                  <tr>
                    <td>
                      <span className="cart-product-amount-ttl color-dark " style={{ fontWeight: "bold" }}>
                        Total Amount
                      </span>
                    </td>
                    <td>
                      <span className="cart-product-amount-price cart-sub-total color-dark" style={{ fontWeight: "bold" }}>
                        {/* @ts-ignore */}
                        {currencyType} {this.props?.myCartList?.order?.data?.attributes?.total}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="btn-proceed">
                <Button className="cart-proceed py-3 px-5" onClick={() => {
                  if (this.state.isLoggedIn) {
                    //@ts-ignore
                    if (JSON.parse(localStorage.getItem("commonSettings"))?.min_cart_val && this.props?.myCartList?.order?.data?.attributes?.total < JSON.parse(localStorage.getItem("commonSettings"))?.min_cart_val) {
                      //@ts-ignore
                      window.notify([{ type: 'error', message: `Minimum Cart Value should be ${JSON.parse(localStorage.getItem("commonSettings"))?.min_cart_val}` }])
                    }
                    else {
                      this.props.history.push("/checkout")
                    }
                  }
                  else {
                    this.props.history.push("/login")
                  }
                }}>Proceed to Checkout</Button>
              </div>
            </div>
            <div className="credit-div">
              <span className="my-span">
                You can earn{" "}
                <label>
                  <img src={rewardIcon} alt='' />
                  {/* @ts-ignore */}
                  {this.props?.myCartList?.order?.data?.attributes?.earn_reward_point} reward points
                </label>{" "}
                for completing your order!
              </span>
            </div>
          </div>
          : <EmptyCart />
        }
        {this.state.bannerImgList?.length > 0 ? this.state.bannerImgList?.map((banner: any) => (
          <div className="mt-4 mb-40 text-center" >
            <img src={banner?.url} alt="" className="img-fluid adv-img" />
          </div>
        )) : new Array(2).fill(0).map(() =>
          <div className="mt-4 h-25">
            <AdvBannerSkeltonLoader />
          </div>)}

        {/* Coupons Modal */}
        <Modal
          isOpen={this.state.showAllCoupons}
          toggle={() => this.toggleCouponModal()}
          className=""
          centered={true}
          modalClassName="popopop"
        >
          <ModalHeader className="coupon-title-bar  border-0" close={<img alt='close-btn' className="toggle-Modal" src={modalCloseIcon} onClick={() => this.toggleCouponModal()} />}>
            <span>{content.viewAvailableCoupons}</span>
          </ModalHeader>
          <ModalBody className="yt-coupon-wrap">
            {/* @ts-ignore */}
            {this.state.allCoupons.length > 0 ? (
              <>
                {this.state.allCoupons?.map((coupon, index) => (
                  <div
                    className="coupon-body-text"
                    onClick={() => this.copyCoupon(coupon, index)}
                    key={index}
                  >
                    <div className="row position-relative mb-3">
                      <div className="col-md-9 col-sm-9">
                        <p
                          // @ts-ignore
                          className={this.state.couponCopied === index ? "active" : null}
                        >
                          {coupon?.attributes?.code}{" "}
                          {this.state.couponCopied === index ? "Code Copied!" : null}
                        </p>
                      </div>
                      <div className="col-md-3 col-sm-3 text-end">
                        <Button className="btn cart-coupon-btn">Apply</Button>
                      </div>
                    </div>
                    <span>
                      {/* @ts-ignore */}
                      Get {coupon?.attributes?.discount_type === "flat" ? `${currencyType} ${coupon?.attributes?.discount}` : `${coupon?.attributes?.discount}%`} Off on order of minimum {currencyType} {coupon?.attributes?.min_cart_value} to {currencyType}
                      {' '} {coupon?.attributes?.max_cart_value}.
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <div className="coupon-body-text noCoupon">
                <p>{content.noCouponsAvailable}</p>
              </div>
            )}
          </ModalBody>
        </Modal>

        {/* Modal For Customized Start */}
        <Modal className="cm-small-modal-6" centered={true} isOpen={this.state && this.state.isOpenCustomize} toggle={() => this.setState({ isOpenCustomize: false })}>
          <ModalHeader className="add-addr-title-bar p-4" close={<img alt='close-btn' style={{ cursor: 'pointer' }} className="toggle-Modal" src={modalCloseIcon} onClick={() => this.setState({ isOpenCustomize: false })} />}>
            <span>In your order</span>
          </ModalHeader>
          <ModalBody className=" yt-add-modal-body">
            <div className="ad-addr-body-wrap">
              <div className="modal-food-mainhead">
                {/* @ts-ignore */}
                {/* <img src={this.state.customizedData?.attributes?.catalogue?.attributes?.non_veg ? FoodTagNonVeg : FoodTagVeg} className="img-fluid " /> */}
                <div className="modal-food-heading">
                  {/* @ts-ignore */}
                  <h3>{this.state.customizedData?.attributes?.catalogue?.attributes?.name}</h3><small className='product-description'>{this.state.customizedData?.attributes?.catalogue?.attributes?.description}</small>
                </div>
                <div className="veg-nonVeg  ml-3 p-1">
                  <img src={this.state.customizedData?.attributes?.catalogue?.attributes?.non_veg ? FoodTagNonVeg : FoodTagVeg} className="img-fluid" />
                  {this.state.customizedData?.attributes?.catalogue?.attributes?.non_veg ? <span>&nbsp;&nbsp;Non-vegetarian</span> : <span>&nbsp;&nbsp;Vegetarian</span>}
                </div>
              </div>

              <Formik
                //@ts-ignore
                innerRef={this.state.formRef}
                initialValues={{
                }}
                validationSchema={() => {
                  //@ts-ignore
                  return Yup.object().shape({
                    radioButton: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
                  })
                }}
                onSubmit={(values) => {
                  console.log("valuesssssssss", values);
                }}

              >
                {(props) => {
                  const { errors, touched, values, handleBlur, handleChange, handleReset, handleSubmit } = props;

                  return (
                    <Form onSubmit={handleSubmit} noValidate style={{ paddingTop: "20px" }}>
                      {/* @ts-ignore */}
                      {this.state.customizedData?.attributes?.catalogue?.attributes?.catalogue_catalogue_attributes.length > 0 &&
                        <>
                          {/* @ts-ignore */}
                          {this.state.customizedData?.attributes?.catalogue?.attributes?.catalogue_catalogue_attributes.map(
                            (attributes: any, index: any) => (
                              <Fragment key={index}>
                                <div className="modal-attributes-section" key={index}>
                                  <span>{attributes?.attributes?.name}</span>
                                  {
                                    attributes?.attributes?.catalogue_catalogue_attribute_values?.length > 0 &&
                                    attributes?.attributes?.catalogue_catalogue_attribute_values?.map((microAttribute: any, index2: any) =>
                                    (
                                      <FormGroup key={index2}>
                                        <span className='modalTitleInputLable'>{microAttribute?.attributes?.name}</span>
                                        <Field checked={microAttribute?.isSelected} value={microAttribute?.attributes?.name} type={attributes?.attributes?.control_type === "radio_button" ? "radio" : "checkbox"}
                                          name={attributes?.attributes?.name} id={microAttribute?.id} className="form-check-input" />
                                      </FormGroup>
                                    ))}
                                </div>
                                {/* @ts-ignore */}
                                {this.state.customizedData?.attributes?.catalogue?.attributes?.catalogue_catalogue_attributes.length - 1 !== index && <div className="modal-section-divider" />}
                              </Fragment>
                            ))}
                        </>
                      }
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="item-total">
              <span>
                {content.itemTotal}
              </span>
              <div className="item-total-flex">
                {/* @ts-ignore */}
                <div>{currencyType} {this.state.customizedData?.attributes?.total_price}</div>
              </div>
            </div>
            {/* @ts-ignore */}
          </ModalFooter>
        </Modal>
        {/* Modal For Customized End */}

        {/* Modal for Delete a Product From Cart Start */}
        <Modal modalClassName="popopop" className="cm-small-modal-4" isOpen={this.state.isCartItemRemove} toggle={() => this.removeProductModalClose()} centered={true}>
          <ModalHeader className="deleteCartProduct-addr-title-bar border-0" close={<img src={modalCloseIcon} alt='' onClick={() => this.removeProductModalClose()} />} >
            <span>{content.removeProduct}</span>
          </ModalHeader>
          <ModalBody className="py-4">
            <div className="text-center deleteCartProduct-addr-body-text px-0 pt-4">
              {content.removeProductTagline}
            </div>
          </ModalBody>
          <ModalFooter className="deleteCart-addr-bottom-bar p-1 border-1 d-flex justify-content-between">
            <Button color="secondary pp-deleteProduct-cart-btn-modal p-3 pp-deleteProdcut-cart-btn-light-grey mr-1" onClick={() => this.removeProductModalClose()}>{content.cancelBtn}</Button>
            <span className="yt-form-spacer-cart" />
            <Button color="secondary pp-deleteProduct-cart-btn-modal p-3 pp-deleteProduct-cart-btn-dark-grey ml-1" onClick={() => this.removeProductFromCart()}>{content.yesDelete}</Button>
          </ModalFooter>
        </Modal>
        {/* Modal for Delete a Product From Cart end */}

      </ >
    );
  }
}

// @ts-ignore
export default withRouter(ShoppingCart)
